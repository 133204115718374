import React, { useCallback } from 'react'
import Modal from 'react-modal'
import styles from 'app.module.css'
import { useLocalStorage } from 'hooks/localstorage.hooks'
import { ReactComponent as Logo } from 'assets/images/gfw-carrier-vessels.svg'
import { WELCOME_MODAL_READED_STORAGE_KEY } from 'data/constants'

declare global {
  interface Window {
    gtag: any
  }
}

Modal.setAppElement('#root')

const WelcomeModal: React.FC = (): React.ReactElement => {
  const [welcomeModalReaded, setWelcomeModalReaded] = useLocalStorage(
    WELCOME_MODAL_READED_STORAGE_KEY
  )
  const dismissWelcomeModal = useCallback(() => {
    setWelcomeModalReaded('true')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal
      overlayClassName={styles.modalOverlay}
      className={styles.modalContentWrapper}
      isOpen={!welcomeModalReaded}
      onRequestClose={dismissWelcomeModal}
    >
      <Logo className={styles.modalLogo} />
      <h2 className={styles.modalTitle}>Welcome to the Carrier Vessel Portal</h2>
      <div className={styles.modalContent}>
        <p>
          To provide a full picture of carrier patterns, the Carrier Vessel Portal uses a
          combination of automatic identification system (AIS) and registry data to show the
          historical activity of carriers, including port visits, loitering and encounter events, as
          well as the RFMO authorization for both carriers and fishing vessels.
        </p>
        <h3>Current status</h3>
        <ul>
          <li>Carrier Vessel Portal was released in 2017.</li>
          <li>
            We are currently working on integrating the Carrier Vessel Portal features into the main
            Global Fishing Watch platform, which has more updated data and technology. This will
            allow us to streamline our data and enhance how users will be able to explore potential
            transshipment information.
          </li>
          <li>
            We are planning releases throughout 2025 to ensure the key functionalities are
            incorporated and that the transition between the current and integrated version of the
            Carrier Vessel Portal is as seamless as possible. Tentative dates include:
            <ul>
              <li>
                April 2025:
                <ul>
                  <li>
                    Access to an ‘in development’ version of the Carrier Vessel Portal into the main
                    Global Fishing Watch platform will be available upon request.
                  </li>
                  <li>
                    Any new data will not appear in the existing Carrier Vessel Portal but
                    historical data will remain accessible.
                  </li>
                </ul>
              </li>
              <li>
                October 2025:
                <ul>
                  <li>
                    Full integration of the Carrier Vessel Portal into the main Global Fishing Watch
                    platform will be available to everyone.
                  </li>
                  <li>The current version of the Carrier Vessel Portal will be archived.</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            To provide feedback on the new features for carrier vessels under development, contact
            us by emailing{' '}
            <a href="mailto:support@globalfishingwatch.org?subject=CVP product development">
              support@globalfishingwatch.org
            </a>{' '}
            with the subject heading ‘CVP product development’.
          </li>
          <li>
            If in the meantime you experience any issue let us know by emailing{' '}
            <a href="mailto:support@globalfishingwatch.org?subject=CVP issues">
              support@globalfishingwatch.org
            </a>{' '}
            with the subject heading ‘CVP issues’.
          </li>
          <h3>Alternative tools</h3>
          <ul>
            <li>
              Before the release of the updated Carrier Vessel Portal, access vessel level identity
              and activity information on all vessels in the{' '}
              <a href="https://globalfishingwatch.org/map/vessel-search">
                Global Fishing Watch and TMT Vessel Viewer
              </a>
              .
              <ul>
                <li>
                  <a href="https://globalfishingwatch.org/platform-updates/">A recent update</a>{' '}
                  includes the analysis of a group of vessels
                </li>
              </ul>
            </li>
            <li>
              To view global fishing-carrier vessel encounters without authorization information,
              toggle on encounter events (AIS) event layer in the{' '}
              <a href="https://globalfishingwatch.org/map/index?start=2024-05-31T00%3A00%3A00.000Z&end=2024-08-31T00%3A00%3A00.000Z&longitude=26&latitude=5.018963891828896&zoom=1.49&dvIn[0][id]=encounters&dvIn[0][cfg][vis]=true&dvIn[1][id]=encounter-events&dvIn[1][cfg][vis]=true&dvIn[2][id]=presence&dvIn[2][cfg][vis]=true&dvIn[3][id]=vms&dvIn[3][cfg][vis]=false&dvIn[4][id]=fishing-ais-pipe-3&dvIn[4][cfg][vis]=false&tV=heatmap&aVM=heatmap-high-res">
                Global Fishing Watch map
              </a>
              .
            </li>
          </ul>
        </ul>
      </div>
      <div className={styles.modalFooter}>
        <button className={styles.modalButton} onClick={dismissWelcomeModal}>
          Dismiss
        </button>
      </div>
    </Modal>
  )
}

export default WelcomeModal
